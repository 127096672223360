import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

const Presentation = () => {
    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <div className="container">
        <div className="row justify-content-center py-5 align-items-center">
          
          <div className="col-lg-6">
            <div>
                <h6 className='titre'>NOTRE MÉTHODE</h6>
                <h2 className='soustitre mt-3'>À travers des méthodes interactives et immersives,</h2>
                <div className='textsous1 mt-4'>AtlasDeleG génère un engagement profond, catalysant la rétention des 
                     connaissances et la mise en pratique des compétences acquises. La formation ne 
                     s'arrête pas là, elle évolue en une relation durable, où les formateurs demeurent des mentors engagés.
                </div>
                <div className='para2 mt-4'>
                Portée par une vision de croissance durable, AtlasDeleG 
                transforme les individus et les entreprises en moteurs de changement, cultivant 
                ainsi la culture de l’amélioration continue. 
                </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="./img/imagepresentation.png"
              style={{ maxWidth: "100%" }}
              alt="imagequisommesnous"
              className='bloc 'data-aos="flip-up"
            />
          </div>
        </div>
      </div>
      
    );
};

export default Presentation;