import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Partenaires = () => {
    const options = {
        loop: true,
        items: 1,
        autoplay: true,
        autoplayTimeout: 1000,
        dots: false,
        center: false,
        responsive: {
          0: {
            items: 2, // Affiche un seul élément à la fois pour les écrans de moins de 768px de largeur
          },
          768: {
            items: 4, // Affiche 4 éléments à la fois pour les écrans de 768px et plus de largeur
          },
        },
      };
    return (
        <div className="container">
  <h6 className="text1 text-center">PARTENAIRES</h6>
  
  <div className="py-4 mt-4" >
    <OwlCarousel className="owl-theme partener" {...options}>
      <div className="carousel-item">
        <img src="./img/pmn.svg" alt="sss" className="carousel-image" />
      </div>
    
      <div className="carousel-item">
        <img src="./img/fitec.svg" alt="" className="carousel-image" />
      </div>
      <div className="carousel-item">
        <img src="./img/certyjob.svg" alt="" className="carousel-image" />
      </div>
    </OwlCarousel>
  </div>
</div>

    
    );
};

export default Partenaires;