import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const cardsData = [
  {
    title: "Diversité des Compétences Multidisciplinaires ",
    icon: "/img/icone1.svg",
    initialText: "AtlasDeleG se démarque par la diversité exceptionnelle des compétences que ses formateurs experts appor ...",
    fullText: "AtlasDeleG se démarque par la diversité exceptionnelle des compétences que ses formateurs experts apportent. Des domaines allant des sciences, de l’informatique, de la finance, de la technologie aux affaires et aux arts sont couverts. Chaque formateur est sélectionné avec soin en fonction de sa maîtrise de son domaine spécifique, ce qui crée un écosystème d'apprentissage enrichi par des perspectives variées. Cette diversité permet aux participants de bénéficier d'une compréhension plus profonde et holistique des sujets abordés.",
  },
  {
    title: "Personnalisation Contextuelle",
    icon: "/img/icone2.svg",
    initialText: "La compréhension des besoins spécifiques de chaque organisation est primordiale pour AtlasDeleG. Avant de ...",
    fullText: "La compréhension des besoins spécifiques de chaque organisation est primordiale pour AtlasDeleG. Avant de concevoir un programme de formation, l'équipe mène des enquêtes approfondies pour saisir la culture, les objectifs et les défis de l'entreprise cliente. Cette approche de personnalisation garantit que les formations sont étroitement alignées sur les objectifs stratégiques de l'organisation, optimisant ainsi l'impact des connaissances acquises.",
  },
  ,
  {
    title: "Quête Permanente de l'Excellence",
    icon: "/img/icone3.svg",
    initialText: "AtlasDeleG adhère à une philosophie de recherche constante de l'excellence. Les formateurs sélectio ...",
    fullText: "AtlasDeleG adhère à une philosophie de recherche constante de l'excellence. Les formateurs sélectionnés sont non seulement des experts dans leurs domaines, mais également des passionnés qui croient en l'amélioration continue. Chaque module de formation est conçu pour inspirer les participants à poursuivre l'excellence, à remettre en question les normes et à repousser leurs limites, tant sur le plan personnel que professionnel.",
  },
  ,
  {
    title: "Méthodologies Interactives et Engageantes",
    icon: "/img/icone4.svg",
    initialText: "Loin des méthodes d'enseignement traditionnelles, AtlasDeleG adopte des approches interactives et ...",
    fullText: "Loin des méthodes d'enseignement traditionnelles, AtlasDeleG adopte des approches interactives et engageantes pour l'apprentissage. Les formateurs intègrent des études de cas réels, des simulations et des ateliers pratiques, créant ainsi une expérience immersive pour les participants. Cette approche favorise l'interaction, la réflexion active et l'application pratique des connaissances, conduisant à une meilleure rétention et à une application efficace des compétences.",
  },
  ,
  {
    title: "Accompagnement Post-Formation",
    icon: "/img/icone5.svg",
    initialText: "AtlasDeleG ne se limite pas à la fin des sessions de formation. Les formateurs restent engagés dans ... ",
    fullText: "AtlasDeleG ne se limite pas à la fin des sessions de formation. Les formateurs restent engagés dans le processus d'apprentissage des participants en offrant un suivi continu. Ils répondent aux questions, offrent des conseils et aident à surmonter les défis rencontrés lors de la mise en pratique des compétences acquises. Cet accompagnement post-formation assure une véritable transformation des participants et un impact durable sur leur performance.",
  },
  ,
  {
    title: "Vision de Croissance Durable",
    icon: "/img/icone6.svg",
    initialText: "Au cœur de la mission d'AtlasDeleG se trouve une vision de croissance durable. Les formations ne sont pas ...",
    fullText: "Au cœur de la mission d'AtlasDeleG se trouve une vision de croissance durable. Les formations ne sont pas simplement des événements isolés, mais des jalons sur la voie d'une amélioration continue. AtlasDeleg encourage les participants à adopter une mentalité de développement à long terme, à investir dans leur propre croissance professionnelle et à devenir des leaders de changement au sein de leurs organisations.",
  },
  // Ajoutez d'autres données de cartes ici...
];

const MyCard = ({ title, icon, initialText, fullText }) => {
  const [showFullText, setShowFullText] = useState(false);
  const textToShow = showFullText ? fullText : initialText;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const cardStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundImage: 'url("/img/fondcard.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: '2rem',
    boxShadow: 'none',
  };

  return (
    <div className="col-md-4">
      <Card sx={cardStyle} className="cardstyle mb-5">
        <CardActionArea sx={{ padding: 0, boxShadow: 'none' }} onClick={toggleText} className="card-action-area">
          <CardContent>
            <Typography variant="h6" component="div">
              <div className="d-flex align-items-center">
                <img src={icon} alt="" width="20px" />
                <div className="ms-2 titre">
                  <div className='titre'>{title}</div>
               
                </div>
              </div>
            </Typography>
            <Typography variant="body2" color="text.secondary" align="left" >
              <div className="d-flex justify-content-start">
                <p className="mt-3" style={{textAlign:"justify"}}>
                  {showFullText ? (
                    <div>
                      <p>{textToShow}</p>
                      <a onClick={toggleText} className="btnvoir">
                        Lire moins
                        <img src="/img/fleche.svg" alt="" className="ms-2" />
                      </a>
                    </div>
                  ) : (
                    <div>
                      <p>{initialText}</p>
                      <a onClick={toggleText} className="btnvoir">
                        Lire plus
                        <img src="/img/fleche.svg" alt="" className="ms-2" />
                      </a>
                    </div>
                  )}
                </p>
              </div>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

const Service = () => {
  return (
    <div className="container">
      <h6 className="text1 text-center">SERVICES</h6>
      <h2 className="text2 py-5">Diversité des Compétences Multidisciplinaires</h2>
      <div className="row d-flex justify-content-center">
        {cardsData.map((card, index) => (
          <MyCard
            key={index}
            title={card.title}
            icon={card.icon}
            initialText={card.initialText}
            fullText={card.fullText}
          />
        ))}
      </div>
    </div>
  );
};

export default Service;
