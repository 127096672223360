import React from 'react';

const Footer = () => {
    return (
        <div className="container">
        <div className="row d-flex justify-content-around align-items-center">
        <div className="col-md-3 text-start">
            <img src="/img/logohaut.svg" alt="" />
            <div className="souslogo mt-3">Au-delà d'une simple délégation de formateurs experts, c'est une fusion de compétences multidisciplinaires qui stimule la croissance organisationnelle.</div>
        </div>
        <div className="col-md-3 text-start">
            <h4 className="localisation">Localisation</h4>
            <div className="mt-3">
            <span className="pays">FRANCE</span> <br />
            <span className="baspays">27 avenue Alfred de Vigny 95230 Soisy-sous-Montmorency</span>
            </div>
        </div>
        <div className="col-md-3 text-start">
            <h4 className="localisation">Contact</h4>
            <div className=" mt-3">
            {/* <span className="pays">+33 6 95239825</span> <br /> */}
            <span className="baspays">paris@atlaskills.com</span>
            </div>
        </div>
    </div>
    </div>
    );
};

export default Footer;