import React from 'react';


const Header = () => {
   
    return (
        <div className='bloc'>
        <div className='logo-container py-4'>
          <img src="/img/logohaut.svg" alt="" className="logo"/>
        </div>
        <div className="sectionimage">
          <div className="container">
            <h6 className="text1">AtlasDeleg</h6>
            <div className="text2">C’est bien plus qu’une simple </div>
            <div className='text3'>délégation de <span className='text3sous'>formateurs experts ;</span> c’est une</div>
            <div className='text4'>fusion de compétences multidisciplinaires, un catalyseur de</div>
            <div className='text4'>croissance organisationnelle.</div>
          </div>
        </div>
        <div className="overlay-image">
          <img src="/img/basimgae.png" alt="" style={{maxWidth:"100%"}} />
        </div>
      </div>
      
    );
};

export default Header;