import React from 'react';
import Header from '../components/Header';
import Presentation from '../components/Presentation';
import Service from '../components/Service';
import Footer from '../components/Footer';
import Partenaires from '../components/Partenaires';

const Accueil = () => {
    return (
        <div className="accueil_section">
            <div className="section_header">
                <Header/>
            </div>
            <div className="section_presentation">
                <Presentation/>
            </div>
            <div className="section_services py-5">
                <Service/>
            </div>
            <div className="section_partenaires py-5">
                <Partenaires/>
            </div>
            <div className="barre"></div>
            <div className="section_footer py-5">
                <Footer/>
            </div>
        </div>
    );
};

export default Accueil;